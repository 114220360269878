import { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";

const hobbies = [
  "crocheting ‧₊˚ 🎐 ⋅",
  "learning silly dances on tiktok 👻",
  "reading mangas 🫠",
  "playing nonogram 👀",
  "making new spotify playlists 🫧🎧",
  "ordering boba ♡‧₊˚",
  "rewatching 'interstella' for the 1000th time",
  "redecorating my notion page for the 378th time that month 🩰",
  "making oatmilk matcha latte ₊˚ 🍵",
  "taking pictures with my five different cameras °📷 ༘ ⋆｡˚",
  "scrapbook-ing",
  "learning a new vietnamese food recipe",
];

function useWordCycle(words) {
  const [currentWord, setCurrentWord] = useState(
    words[Math.floor(Math.random() * words.length)]
  );

  const handleClick = () => {
    const currentIndex = words.indexOf(currentWord);
    const nextIndex = (currentIndex + 1) % words.length;
    setCurrentWord(words[nextIndex]);
  };

  return [currentWord, handleClick];
}

function Home() {
  useEffect(() => {
    document.title = "bobobapal ・ᡣ𐭩";
  }, []);

  const [currentHobby, handleHobbyClick] = useWordCycle(hobbies);

  return (
    <div>
      <h2>hello there, i'm Leah 🤍 </h2>
      <p>
        welcome to my little page!

      </p>
      <p>
        after 18 years living in hanoi, vietnam, i am now a college junior at the university of richmond. when i'm not trying to be an academic weapon, you can probably catch me{" "}
        <a class="clickable" id="hobbies" onClick={handleHobbyClick}>
          {currentHobby}
        </a>
        .
      </p>
      <p>
        reach out to me anytime at{" "}
        <a href="mailto:leah.phw@gmail.com" class="clickable">
          leah.phw@gmail.com
        </a>
      </p>
    </div>
  );
}

export default Home;