import { useEffect } from "react";
import { NavLink } from "react-router-dom";

function Projects() {
  useEffect(() => {
    document.title = "leah codes (✿˵•́ ૩•̀˵)";
  }, []);

  return (
    <div>
      <p>
        nicer things are coming soon! meanwhile any feedback is greatly appreciated ٩(◕‿◕)۶ more archives can be found on my{" "}
        <a href="https://github.com/leahphw" class="clickable" target="_blank">
          github
        </a>{" "} ☃
      </p>
      <br/>
      <p>
        <a
          href="https://github.com/leahphw/pixishare-dapp"
          class="clickable"
          target="_blank"
        >
          <b>pixi-share</b>
        </a>{" "}
        <br />
        instagram clone but decentralized ⋅˚₊‧
      </p>
    </div>
  );
}

export default Projects;