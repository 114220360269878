import { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";

const majors = [
  "computer science 🪩",
  "mathematics 📑",
  "economics ♣︎",
  "pre-engineering (?)",
  "visual arts and media (●´⌓`●)",
];

function useWordCycle(words) {
  const [currentWord, setCurrentWord] = useState(
    words[Math.floor(Math.random() * words.length)]
  );

  const handleClick = () => {
    const currentIndex = words.indexOf(currentWord);
    const nextIndex = (currentIndex + 1) % words.length;
    setCurrentWord(words[nextIndex]);
  };

  return [currentWord, handleClick];
}

function About() {
  useEffect(() => {
    document.title = "leah learns ₊˚🎧⊹♡";
  }, []);

  const [mymajors, handleMajorClick] = useWordCycle(majors);


  return (
    <div>
      <br />
      <br />
      <p>
        here are some small facts about me ( ๑‾̀◡‾́)σ" i like cooking, reading, and overall learning new (possibly random) things.
        <br />
        <br />
        i am a fast learner and i really enjoy applying what i know to create something meaningful. 
        so, i embrace every learning opportunity that comes my way and will always give it my best shot!
        <br />
        <br />
        while i'm still trying to figure out my passions and ambitions, i like to try new things and push
        myself out of my comfort zone. (❛‿❛) i love to expand my knowledge from the people around me so please connect 🤍
      </p>
      <br />
      <br />
      <p>
        <h2>currently</h2>
      </p>
      <p>
        i'm a junior at{" "}
        <a href="https://www.richmond.edu/" target="_blank" class="clickable">
          university of richmond
        </a>
        , working on my{" "}
        <a class="clickable" id="majors" onClick={handleMajorClick}>
          {mymajors}
        </a> degree and <a href="https://blog.richmond.edu/hive-lab/" target="_blank" class="clickable">
          autonomous dancing robots research!
        </a>
      </p>
      <br />
      <br />
      <p>
        <h2>i have also dabbled in</h2>
        <h3>some internships 💻 </h3>
      </p>
      <ul>
        <li>improving search quality - bias suppresing @ <a href="https://www.google.com/" class="clickable" target="_blank">Google Search</a> </li>
        <li>
          developing a subgraph for direct data query from the ethereum blockchain for web3 games - <a
            href="https://github.com/leahphw/pgx-liquidity-subgraph"
            class="clickable"
            target="_blank"
          >
            Github
          </a>
        </li>
        <li>
          creating websites for non-profits with <a
            href="https://www.developforgood.org/?gad_source=1&gclid=CjwKCAjw17qvBhBrEiwA1rU9w1hYghMW1CtS04zqAlNYKxDJpogjQdA3uJmktljnX9y-lx26vqhkKhoC5JwQAvD_BwE"
            class="clickable"
            target="_blank"
          >
            Develop for Good
          </a>
        </li>
        <li>
          writing test suites for some web3 games @ <a
            href="https://mirailabs.co/"
            class="clickable"
            target="_blank"
          >
            Mirai Labs
          </a>
        </li>
        <li>
          writing some website code and organizing travel events for{" "}
          <a
            href="http://urgwc.com"
            target="_blank"
            class="clickable"
          >
            Girls Who Code
          </a>
        </li>
      </ul>
      <h3>academics 📖</h3>
      <ul>
        <li>courseworks: to be updated (with my personal notes and study tips ^^)</li>
      <li>
          ta for data structures and algorithms
        </li>
        <li>participating in competitions and hackathons (i like academic validation) like ICPC and CTFs!</li>
      </ul>
    </div>
  );
}

export default About;